import Cookies from 'js-cookie'

export function parseJwt() {
    const token = Cookies.get('token')
    console.log(`token: ${token}`)
    if (token != undefined) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    return undefined
};

export function checkLogging() {
    return Cookies.get('isLogged')
}