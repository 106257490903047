import '@fontsource/roboto';
import * as React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from "react";
import { parseJwt } from '../../utils/cookiesUtils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarProvider, useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


let alumnoName;


export default function VerAmigos() {

    const [amigos, setAmigos] = useState([])
    const [AmigoEliminar, setAmigoEliminar] = useState('')
    const [open, setOpen] = React.useState(false);
    const [openEliminar, setOpenEliminar] = React.useState(false);
    const [openTitulo, setOpenTitulo] = React.useState(false);

    const handleClickOpenTitulo = () => {
      setOpen(true);
    };
  
    const handleCloseTitulo = () => {
      setOpen(false);
    };

    const UsuarioAmigo = useRef();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleFalse = () => {
        setOpen(false);
    };

    const handleClickOpenE = (amigoName) => {
        setOpenEliminar(true);
        setAmigoEliminar(amigoName)
    };

    const handleFalseE = () => {
        setOpenEliminar(false);
    };

    function AgregarAmigoBoton() {
        const { enqueueSnackbar } = useSnackbar();

        const handleClickVariant = (mensaje, variant) => {
            enqueueSnackbar(mensaje, { variant });
        };

        const handleClose = () => {
            const data = parseJwt()
            const name = UsuarioAmigo.current.value
            let alumno;
            if (data != undefined) {
                alumno = data.usuario
            }
            const body = {
                "AlumnoCreador": alumno.name,
                "AlumnoDestinatario": name
            }

            const options = {
                withCredentials: true
            }

            axios.post(`${process.env.REACT_APP_CURRENT_URL_TO_API}/solicitud/`, body, options)
                .then((response) => {
                    handleClickVariant('¡Solicitud de amistad enviada!', 'info');
                })
                .catch((error) => {
                    console.log(error.response.data)
                    handleClickVariant(error.response.data.message, 'error');
                })

            setOpen(false);
        };

        return (
            <React.Fragment>
                <Button color="success" onClick={handleClose}>Enviar solicitud</Button>
            </React.Fragment>
        );
    }

    function EliminarAmigo() {
        const { enqueueSnackbar } = useSnackbar();

        const handleClickVariant = (mensaje, variant) => {
            enqueueSnackbar(mensaje, { variant });
        };

        const handleClose = () => {
            const data = parseJwt()
            let alumno;
            if (data != undefined) {
                alumno = data.usuario
            }

            const options = {
                withCredentials: true
            }

            axios.delete(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos/${alumno.name}/amigos?nameAmigo=${AmigoEliminar}`, options)
                .then(response => {
                    setAmigos(amigos.filter(item => item.Usuario.name !== AmigoEliminar));
                    handleClickVariant(response.data.message, 'info');
                })
                .catch(err => {
                    handleClickVariant(err.response.data.message, 'error');
                })
                setOpenEliminar(false)
        }
        return (
            <React.Fragment>
                <Button color="error" onClick={handleClose}>Eliminar amigo</Button>
            </React.Fragment>
        );
    }


    useEffect(() => {
        let arrayAmigos = []
        const data = parseJwt()
        if (data != undefined) alumnoName = data.usuario.name
        const options = {
            withCredentials: true
        }
        axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos/`,options)
            .then((response) => {
                const alumnos = response.data.message
                console.log(alumnos)
                //setAmigos(response.data.message.Amigos)
                alumnos.map((alumno) => {
                    if (alumno.Amigos != undefined) {
                        alumno.Amigos.map((amigo) => {
                            if (amigo == alumnoName) arrayAmigos.push(alumno)
                        })
                    }
                })
                console.log(arrayAmigos)
                setAmigos(arrayAmigos)
            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }, [])

    return (
        <React.Fragment >
            <SnackbarProvider maxSnack={3}>
                <div style={{ "width": "86%", "margin": "auto", "marginBottom": "20px", "marginTop": "20px" }}>
                    <Button variant="contained" onClick={handleClickOpen}>
                        Añadir amigo
                    </Button>
                    <Dialog open={open} onClose={handleFalse}>
                        <DialogTitle>Añadir amigo</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Nombre de usuario"
                                type="email"
                                fullWidth
                                variant="standard"
                                inputRef={UsuarioAmigo}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleFalse}>Cancelar</Button>
                            <AgregarAmigoBoton />
                        </DialogActions>
                    </Dialog>
                </div>
                <div style={{ "width": "86%", "margin": "auto", "marginBottom": "20px", "marginTop": "20px" }}>
                    <Dialog open={openEliminar} onClose={handleFalseE}>
                        { () => {return <DialogTitle></DialogTitle>}}
                        <DialogTitle>¿Esta seguro de eliminar a {AmigoEliminar}? </DialogTitle>
                        <DialogActions>
                            <Button onClick={handleFalseE}>Cancelar</Button>
                            <EliminarAmigo />
                        </DialogActions>
                    </Dialog>
                </div>
                <TableContainer
                    component={Paper}
                    sx={{
                        "width": "86%",
                        "margin": "auto"
                    }}
                >
                    <CssBaseline />
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Amigos: {amigos.length}</TableCell>
                                <TableCell align="left">Nombre</TableCell>
                                <TableCell align="left">Apellido</TableCell>
                                <TableCell align="left">Curso</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {amigos.map((row) => (
                                <TableRow
                                    key={row.Usuario.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.Usuario.name}
                                    </TableCell>
                                    <TableCell align="left">{row.Nombre}</TableCell>
                                    <TableCell align="left">{row.Apellido}</TableCell>
                                    <TableCell align="left">{row.Curso}</TableCell>
                                    <IconButton aria-label="delete" color="secondary" onClick={ () => {handleClickOpenE(row.Usuario.name)}}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </SnackbarProvider>
        </React.Fragment >
    );
}