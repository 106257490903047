import '@fontsource/roboto';
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import axios from 'axios';
import { useEffect, useState, useContext } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { NoteContext } from '../CreateNote'
import Icon from '@mui/material/Icon';
import { parseJwt, checkLogging } from '../../../utils/cookiesUtils'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.map(e => e.name).indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function StepOne() {

    const theme = useTheme();

    const { persons, fechaNota, tituloNota, desc } = useContext(NoteContext);
    const [personName, setPersonName] = persons
    const [fechaHora, setfechaHora] = fechaNota;
    const [alumnos, setAlumnos] = useState([])
    const [titulo, setTitulo] = tituloNota
    const [descripcion, setDescripcion] = desc

    useEffect(() => {
        const data = parseJwt()
        if (data == undefined) return undefined;
        const options = {
            withCredentials: true
        }
        axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos/${data.usuario.name}/amigos`, options)
            .then((receive) => {
                const alumnosData = receive.data
                console.log(alumnosData.message.Amigos)
                setAlumnos(alumnosData.message.Amigos)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        value.map(amigo => {
            setPersonName(
                personName.concat({
                    "Alumno": amigo,
                    "estado": "pendiente"
                })
            );
        })
    };

    const handleTituloChange = async (event) => {
        setTitulo(state => ({ ...state }))
        setTitulo(event.target.value)
    }

    const handleDescChange = async (event) => {
        setDescripcion(state => ({ ...state }))
        setDescripcion(event.target.value)
    }

    const handleDelete = (h) => {
        setPersonName((personName) =>
            personName.filter((hashtag) => hashtag !== h)
        )
    }

    return (
        <React.Fragment>
                <Container maxWidth="sm">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Grid container spacing={3} columns={{ xs: 1, sm: 2, md: 2 }}>
                            <Grid item xs={1} sm >
                                <TextField
                                    multiline
                                    required
                                    sx={{ width: "100%" }}
                                    id="standard-basic"
                                    label="Titulo"
                                    variant="standard"
                                    onChange={handleTituloChange}
                                    value={titulo}
                                />
                            </Grid>
                            <Grid item xs={1} sm >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="Fecha y hora"
                                        value={fechaHora}
                                        onChange={(newValue) => {
                                            setfechaHora(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={1} sm={2} >
                                <TextField
                                    multiline
                                    sx={{ width: "100%" }}
                                    id="standard-basic"
                                    label="Descripción"
                                    onChange={handleDescChange}
                                    variant="standard"
                                    value={descripcion}
                                />
                            </Grid>
                            <Grid item xs={1} sm={2}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-multiple-chip-label">Amigos</InputLabel>
                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={personName}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Amigos" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value, index) => (
                                                    <Chip key={index} label={value.Alumno} onMouseDown={e => {
                                                        e.stopPropagation()
                                                    }}
                                                        onDelete={() => {
                                                            handleDelete(value)
                                                        }}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {alumnos.map((amigo) => (
                                            <MenuItem
                                                key={amigo}
                                                value={amigo}
                                                style={getStyles(amigo, personName, theme)}
                                            >
                                                {amigo}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
        </React.Fragment>
    );

}