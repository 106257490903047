import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CommentIcon from "@mui/icons-material/Comment";
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import { useEffect, useState } from "react";
import { parseJwt, checkLogging } from '../../utils/cookiesUtils'
import Container from '@mui/material/Container';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { red } from '@mui/material/colors';
import { Paper } from "@mui/material";

export default function NotificacionesViewAll() {

    const [notificaciones, setNotificaciones] = useState([])
    const [dataUsuario, setDataUsuario] = useState({})

    const data = parseJwt()

    useEffect(() => {
        let notificacionesPropias = [];
        setDataUsuario(data)
        const options = {
            withCredentials: true
        }
        if (data != undefined) {
            axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos?User=${data.usuario.name}`, options)
                .then((response) => {
                    const alumno = response.data.message[0]
                    console.log(alumno)
                    if (alumno != undefined) {
                        alumno.Notificaciones.map((solicitud) => {
                            if (solicitud.NameRemitente != data.usuario.name)
                                notificacionesPropias.push(solicitud)
                        })

                        setNotificaciones(notificacionesPropias)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])

    const handleNotificaciones = (solicitud, respuesta) => {
        const options = {
            withCredentials : true
        }
        if (dataUsuario != undefined) {
            axios.delete(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos/${solicitud._id}/noti?nameUsuario=${dataUsuario.usuario.name}`, options)
                .then((response) => {
                    console.log(response)
                })
                .catch(error => console.log(error))
        }
    }


    const handleRemoveItem = (e) => {
        setNotificaciones(notificaciones.filter(item => item._id !== e._id));
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", margin: "auto" }}>
                {
                notificaciones.length === 0 ? 
                   
                        <h1>No hay notificaciones</h1>
                    
                :
                
                notificaciones.map((value) => {
                    const labelId = `checkbox-list-label-${value}`;

                return (
                <Paper
                    sx={{ margin: "10px" }}
                >
                    <ListItem
                        key={value._id}
                        secondaryAction={
                            <Container>
                                <IconButton
                                    onClick={() => {
                                        handleRemoveItem(value)
                                        handleNotificaciones(value, "rechazada")
                                    }}
                                    edge="end"
                                    aria-label="comments"
                                    sx={{ color: "#e60000" }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Container>
                        }
                        disablePadding
                    >
                        <ListItemButton
                            role={undefined}
                            dense
                        >
                            <ListItemText id={labelId} primary={`${value.DescripcionNotificacion}`} />
                        </ListItemButton>
                    </ListItem>
                </Paper>
                );
                })}
            </List>
        </React.Fragment>
    );
}
