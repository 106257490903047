import '@fontsource/roboto';
import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import Chip from '@mui/material/Chip';
import { NoteContext } from '../CreateNote'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MateriasSelect from './materiasSelect';
import FechasRecordar from './fechasRecordar'
import Divider from '@mui/material/Divider';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export const RecordatorioContext = createContext([{}, () => { }]);


export default function StepTwo() {
    const textRef = useRef();
    const { RecordatorioObj } = useContext(NoteContext);
    const [RecObj, setRecData] = RecordatorioObj
    const [materiasName, setMateriasName] = useState([])
    const [fechasRec, setFechasRec] = useState([])
    const [tareasObj, setTareasObj] = useState([])

    const values = {
        "materiasData": [materiasName, setMateriasName],
        "fechaRec": [fechasRec, setFechasRec],
        "tareas": [tareasObj, setTareasObj],
    }

    const handleDelete = (chipToDelete) => () => {
        setRecData((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleAddRec = () => {
        let Recordatorio = textRef.current.value;
        let OBJETORec = {
            "DescripcionRecordatorio": Recordatorio,
            "Materia": materiasName,
            "FechasRecordar": fechasRec
        }
        console.log(fechasRec)
        setRecData(state => ({ ...state }))
        setRecData(RecObj.concat(OBJETORec))
    }




    return (
        <RecordatorioContext.Provider value={values}>
            <React.Fragment>
                <Container maxWidth="sm">
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Paper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                                backgroundColor: "#272727",
                            }}
                            component="ul"
                        >
                            {RecObj.map((data) => {
                                return (
                                    <ListItem key={data._id}>
                                        <Chip
                                            label={data.DescripcionRecordatorio}
                                            onDelete={handleDelete(data)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </Paper>
                        <Grid container spacing={3} columns={{ xs: 1, sm: 2, md: 2 }}>
                            <Grid item xs={1} sm={2} >
                                <TextField
                                    multiline
                                    sx={{ width: "100%" }}
                                    id="standard-basic"
                                    inputRef={textRef}
                                    label="Descripción"
                                    variant="standard"
                                />
                            </Grid>
                            <MateriasSelect />
                            <Divider variant="middle" />
                            <FechasRecordar />
                        </Grid>
                        <Button
                            onClick={handleAddRec}
                            size="small"
                            sx={{
                                width : "100%",
                            }}
                        >
                            Agregar recordatorio
                        </Button>
                    </Box>
                </Container>
            </React.Fragment>
        </RecordatorioContext.Provider>
    );
}