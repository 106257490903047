import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Home";
import SignIn from './SignIn';
import SignUp from './SignUp'
import PrimarySearchAppBar from './appbar'
import ViewAllNotes from './Notas/viewAll'
import CrearNota from './Notas/CreateNote'
import VerAmigos from "./Amigos/VerAmigos";
import SolicitudesViewAll from './Solicitudes/viewAll'
import NotificacionesViewAll from './Notificaciones/viewAll'
import ViewSingle from './Notas/viewSingle/viewSingle'

export default function Router() {

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<PrimarySearchAppBar />}>
                    <Route index element={<Home />} />
                    <Route path="/notas" element={<ViewAllNotes/>} />
                    <Route path="/crearNotas" element={<CrearNota/>} />
                    <Route path="/veramigos" element={<VerAmigos />} />
                    <Route path='/solicitudes' element={<SolicitudesViewAll />} />
                    <Route path='/notificaciones' element={<NotificacionesViewAll />} />
                    <Route path='vernota/:id' element={<ViewSingle/>} />
                </Route>
                <Route path="/login" element={<SignIn />} />
                <Route path="/singup" element={<SignUp />} />
            </Routes>
        </HashRouter>
    )
}