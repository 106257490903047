import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import { ViewNotaContext } from './viewSingle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MateriasSelect from './materiasSelect'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';


export default function AgregarTarea() {

    const { notaMod, agregarTarea, IndexRecEdit, indexFecha, indexTarea, DeleteTarea } = useContext(ViewNotaContext);
    const checkRef = useRef();
    const [openTarea, setOpenTarea] = agregarTarea
    const [nota, setNota] = notaMod
    const [indexRec] = IndexRecEdit
    const [indexFechaT] = indexFecha
    const [indexTareaF] = indexTarea
    const [openTareaDelete, setOpenTareaDelete] = DeleteTarea
    const notaNew = nota



    const handleClickOpenRec = () => {
        setOpenTarea(true);
    };


    const handleSendRec = (t) => {
        let checkNota = checkRef.current.checked;
        const tarea = {
            "Estado": "en proceso",
            "DescripcionTarea": t,
            "Nota": checkNota
        }
        if (indexTareaF == undefined) {
            notaNew.Recordatorios[indexRec].FechasRecordar[indexFechaT].Tareas.push(tarea)
            setNota(notaNew)
            setOpenTarea(false);
            return 0;
        }

        else {
            notaNew.Recordatorios[indexRec].FechasRecordar[indexFechaT].Tareas[indexTareaF] = tarea
        }
        setNota(notaNew)
        setOpenTarea(false);
    };

    const handleCloseRec = () => {
        setOpenTarea(false);
    };

    const handleDeleteTarea = () => {
        notaNew.Recordatorios[indexRec].FechasRecordar[indexFechaT].Tareas.splice(indexTareaF, 1)
        setNota(notaNew)
        setOpenTareaDelete(false)
    }

    const handleCloseTareaDelete = () => {
        setOpenTareaDelete(false)
    }

    return <React.Fragment>
        <Dialog open={openTarea} onClose={handleCloseRec}>
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Agregar reunion
                </DialogContentText>
                <TextField
                    id="desc"
                    name="desc"
                    multiline sx={{ width: "100%" }}
                    label="Descripción"
                    variant="standard"
                />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                >
                    <FormGroup>
                        <FormControlLabel
                            name="check"
                            control={
                                <Checkbox
                                    inputRef={checkRef}
                                />
                            }
                            label="Con nota"
                        />
                    </FormGroup>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseRec}>Cancel</Button>
                <Button onClick={() => {
                    handleSendRec(document.getElementById('desc').value)
                }}>Modificar</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openTareaDelete}
            onClose={handleCloseTareaDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Eliminar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Esta seguro de eliminar la tarea?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseTareaDelete}>Cancelar</Button>
                <Button sx={{ color: "red" }} onClick={handleDeleteTarea} autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment >;

}