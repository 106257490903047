import '@fontsource/roboto';
import * as React from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useEffect, useState, createContext } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import EditTitulo from './EditTitulo'
import AgregarRec from './AgregarRec'
import AgregarFecha from './AgregarFecha'
import AgregarTarea from './AgregarTarea';
import AgregarReunion from './AgregarReunion'
import ClearIcon from '@mui/icons-material/Clear';
import Switch from '@mui/material/Switch';
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';
import { Grid } from '@mui/material';
import { parseJwt } from '../../../utils/cookiesUtils';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';


export const ViewNotaContext = createContext([{}, () => { }]);

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));



export default function ViewSingle() {
    const params = useParams()
    const [nota, setNota] = useState({})
    const [fechaCreacion, setFechaCreacion] = useState(new Date())
    const [amigos, setAmigos] = useState([])
    const [recordatorios, setRecordatorios] = useState([])
    const [materias, setMaterias] = useState([])
    const [reuniones, setReuniones] = useState([])
    const [openTitulo, setOpenTitulo] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [openRecEdit, setOpenRecEdit] = React.useState(false);
    const [openFecEdit, setOpenFecEdit] = React.useState(false);
    const [indexRec, setIndexRec] = React.useState(0);
    const [openTarea, setOpenTarea] = React.useState(false)
    const [indexFecha, setIndexFecha] = React.useState(0)
    const [openReunion, setOpenReunion] = React.useState(false)
    const [indexReunion, setIndexReunion] = React.useState(0)
    const [openDeleteRec, setOpenDeleteRec] = React.useState(false)
    const [openDeleteFecha, setOpenDeleteFecha] = React.useState(false)
    const [indexFechaRecordar, setIndexFechaRecordar] = React.useState(0)
    const [indexTarea, setIndexTarea] = React.useState(0)
    const [openTareaDelete, setOpenTareaDelete] = useState(false)
    const [openReunionDelete, setOpenReunionDelete] = React.useState(false)
    const [openDeleteNOTA, setOpenDeleteNOTA] = React.useState(false)
    const [editar, setEditar] = useState("none")
    const [mostrarEditar, setMostrarEditar] = useState("none")
    const [loading, setLoading] = React.useState(false);



    const values = {
        "notaMod": [nota, setNota],
        "amigos": [amigos, setAmigos],
        "recordatorios": [recordatorios, setRecordatorios],
        "materias": [materias, setMaterias],
        "reuniones": [reuniones, setReuniones],
        "editTitulo": [openTitulo, setOpenTitulo],
        "agregarRec": [openRec, setOpenRec],
        "EditRec": [openRecEdit, setOpenRecEdit],
        "IndexRecEdit": [indexRec, setIndexRec],
        "egregarFec": [openFecEdit, setOpenFecEdit],
        "agregarTarea": [openTarea, setOpenTarea],
        "indexFecha": [indexFecha, setIndexFecha],
        "agregarReunion": [openReunion, setOpenReunion],
        "indexReu": [indexReunion, setIndexReunion],
        "DeleteRec": [openDeleteRec, setOpenDeleteRec],
        "DeleteFecha": [openDeleteFecha, setOpenDeleteFecha],
        "indexFechaRecordar": [indexFechaRecordar, setIndexFechaRecordar],
        "indexTarea": [indexTarea, setIndexTarea],
        "DeleteTarea": [openTareaDelete, setOpenTareaDelete],
        "DeleteReunion": [openReunionDelete, setOpenReunionDelete],
        "DeleteNOTA": [openDeleteNOTA, setOpenDeleteNOTA]
    }

    const setDataFromNota = (Nota) => {
        setNota(Nota);
        setFechaCreacion(new Date(Nota.createdAt))
        setAmigos(Nota.Alumnos)
        setRecordatorios(Nota.Recordatorios)
        setMaterias(Nota.Recordatorios.Materia)
        setReuniones(Nota.Reuniones)
    }

    useEffect(() => {
        const Nota = nota
        if (Nota.Recordatorios == undefined) return undefined
        setFechaCreacion(new Date(Nota.createdAt))
        setAmigos(Nota.Alumnos)
        setRecordatorios(Nota.Recordatorios)
        setMaterias(Nota.Recordatorios.Materia)
        setReuniones(Nota.Reuniones)
    }, [nota])

    const handleClickOpenTitulo = () => {
        setOpenTitulo(true);
    };

    const handleClickOpenDeleteNOTA = () => {
        setOpenDeleteNOTA(true)
    }

    const handleClickOpenRec = (index) => {
        setOpenRec(true)
        setIndexRec(index)
    }

    const handleClickOpenDeleteRec = (index) => {
        setOpenDeleteRec(true)
        setIndexRec(index)
    }

    const handleClickOpenDeleteFecha = (index) => {
        setOpenDeleteFecha(true)
        setIndexFechaRecordar(index)
    }

    const handleClickOpenDeleteTarea = (index, indexFecha, indexTarea) => {
        setOpenTareaDelete(true)
        setIndexRec(index)
        setIndexFecha(indexFecha)
        setIndexTarea(indexTarea)
    }

    const handleClickOpenDeleteReunion = (index) => {
        setOpenReunionDelete(true)
        setIndexReunion(index)
    }

    const handleClickOpenFec = (index) => {
        setOpenFecEdit(true)
        setIndexRec(index)
    }

    const handleClickOpenTarea = (index, indexFecha, indexTarea) => {
        setOpenTarea(true)
        setIndexRec(index)
        setIndexFecha(indexFecha)
        setIndexTarea(indexTarea)
    }

    const handleClickOpenReunion = (index) => {
        setOpenReunion(true)
        setIndexReunion(index)
    }

    const handleChangeEstadoTarea = (indexrec, indexfec, indextarea) => {
        const notaNew = nota
        notaNew.Recordatorios[indexrec].FechasRecordar[indexfec].Tareas[indextarea].Estado == "en proceso" ?
            notaNew.Recordatorios[indexrec].FechasRecordar[indexfec].Tareas[indextarea].Estado = "finalizado" :
            notaNew.Recordatorios[indexrec].FechasRecordar[indexfec].Tareas[indextarea].Estado = "en proceso"
        console.log(nota.Recordatorios[indexrec].FechasRecordar[indexfec].Tareas[indextarea].Estado)
        setNota(notaNew)
        setDataFromNota(nota)
    }

    const checkSelected = (indexrec, indexfec, indextarea) => {
        const check = nota.Recordatorios[indexrec].FechasRecordar[indexfec].Tareas[indextarea].Estado == "en proceso" ?
            false : true
        return check
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/notas/${params.id}`)
            .then(response => {
                const Nota = response.data.message;
                setDataFromNota(Nota);
                const data = parseJwt()
                if (data != undefined && data.usuario.name == Nota.AlumnoCreador) setEditar("content")
            })
    }, [])

    function handleClick() {
        setLoading(true);
        const body = { "nota" : nota }
        axios.patch(`${process.env.REACT_APP_CURRENT_URL_TO_API}/notas/${params.id}`, body)
            .then((data) => {
                setLoading(false);
                setMostrarEditar("none")
            })
            .catch((error) => {
                console.log(error)
                setLoading(false);
                setMostrarEditar("none")
            })
    }

    return (
        <ViewNotaContext.Provider value={values}>


            <EditTitulo />

            <AgregarRec />

            <AgregarFecha />

            <AgregarTarea />

            <AgregarReunion />

            <Grid
                container
                sx={{ minWidth: 275, maxWidth: '80%', margin: 'auto', marginTop: '35px', marginBottom: "7px" }}
            >
                <LoadingButton
                    sx={{ marginRight: "7px", display: `${mostrarEditar}` }}
                    color="secondary"
                    onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                >
                    Guardar
                </LoadingButton>
                <Button onClick={() => { setMostrarEditar("content") }} sx={{ marginLeft: "auto", display: `${editar}` }} variant="outlined" startIcon={<EditIcon />}>
                    Editar
                </Button>
            </Grid>
            <Grid>

                <Card sx={{ minWidth: 275, maxWidth: '80%', margin: 'auto', marginTop: '0', marginBottom: "40px" }}>
                    <CssBaseline />
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color={nota.Estado === "en proceso" ? "yellow" : "red"} gutterBottom>
                            {nota.Estado}
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ display: "flex" }}>
                            {nota.Titulo}
                            <Container sx={{ display: "contents" }}>
                                <IconButton aria-label="edit" onClick={handleClickOpenTitulo} size="small" sx={{ marginLeft: "17px", display: `${mostrarEditar}` }}>
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                                <Tooltip title="Eliminar" placement="bottom">
                                    <IconButton aria-label="edit" onClick={() => { handleClickOpenDeleteNOTA() }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                        <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {nota.Descripcion}
                        </Typography>
                        <Typography variant="body2">
                            creada por {nota.AlumnoCreador} el {fechaCreacion.getDate()}/{fechaCreacion.getDay()}/{fechaCreacion.getFullYear()}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ marginTop: '20px' }}>
                            Amigos en la nota: {amigos.length}
                        </Typography>
                        <Paper variant="outlined"
                            sx={{
                                display: 'flex',
                                justifyContent: 'left',
                                flexWrap: 'wrap',
                                listStyle: 'none',
                                p: 0.5,
                                m: 0,
                                padding: "7px"
                            }}
                            component="ul"
                        >
                            {amigos.map((data) => {

                                return (
                                    <ListItem key={data.Alumno}>
                                        <Chip
                                            color={data.estado == "aceptada" ? "success" : data.estado == "rechazada" ? "error" : "warning"}
                                            label={data.Alumno}
                                        />
                                    </ListItem>
                                );
                            })}
                        </Paper>

                        <Tooltip title="Agregar recordatorio" placement="right">
                            <IconButton aria-label="edit" onClick={() => { handleClickOpenRec(undefined) }} size="small" sx={{ marginTop: "20px", display: `${mostrarEditar}` }}>
                                <AddIcon fontSize="inherit" sx={{ color: "greenyellow" }} />
                            </IconButton>
                        </Tooltip>

                        {recordatorios.map((data, index) => {
                            return (
                                <Paper variant="outlined"
                                    sx={{
                                        display: 'block',
                                        justifyContent: 'left',
                                        flexWrap: 'wrap',
                                        listStyle: 'none',
                                        p: 0.5,
                                        m: 0,
                                        marginTop: "10px",
                                        padding: "7px"
                                    }}
                                    component="div"
                                >
                                    <Typography variant="h5" component="div" sx={{ display: "flex" }}>
                                        <Paper sx={{
                                            width: "100%", display: "flex", marginLeft: "auto", alignItems: "flex-end",
                                            justifyContent: "space-between", padding: "10px"
                                        }}  >
                                            {data.DescripcionRecordatorio}
                                            <Container sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                margin: "0px"
                                            }}>
                                                <IconButton aria-label="edit" onClick={() => {
                                                    handleClickOpenRec(index)
                                                }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                    <EditIcon fontSize="inherit" />
                                                </IconButton>
                                                <Tooltip title="Eliminar" placement="bottom">
                                                    <IconButton aria-label="edit" onClick={() => { handleClickOpenDeleteRec(index) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                        <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Container>
                                        </Paper>
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        Materias: {data.Materia.length}
                                    </Typography>
                                    <Paper variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            p: 0.5,
                                            m: 0,
                                            padding: "7px"
                                        }}
                                        component="ul"
                                    >
                                        {data.Materia.map((data) => {

                                            return (
                                                <ListItem key={data.Nombre}>
                                                    <Chip
                                                        label={data.Nombre}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </Paper>
                                    <Tooltip title="Agregar fecha recordar" placement="right">
                                        <IconButton aria-label="edit" onClick={() => { handleClickOpenFec(index) }} size="small" sx={{ marginTop: "20px", display: `${mostrarEditar}` }}>
                                            <AddIcon fontSize="inherit" sx={{ color: "greenyellow" }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Typography variant="caption" display="block" sx={{ marginTop: "17px" }}>
                                        Fechas a recordar: {data.FechasRecordar.length}
                                    </Typography>

                                    <Paper variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            p: 0.5,
                                            m: 0,
                                            padding: "7px"
                                        }}
                                        component="ul"
                                    >
                                        {data.FechasRecordar.map((data, indexFechaRecordar) => {
                                            console.log(data)
                                            return (
                                                <Paper elevation='0'
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        flexWrap: 'wrap',
                                                        listStyle: 'none',
                                                        p: 0.5,
                                                        m: 0,
                                                        padding: "7px"
                                                    }}
                                                    component="ul"
                                                >
                                                    <Tooltip title="Eliminar" placement="bottom">
                                                        <IconButton aria-label="edit" onClick={() => { handleClickOpenDeleteFecha(indexFechaRecordar) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                            <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {data.Fechas.map((data, indexFecha) => {
                                                        return (
                                                            <ListItem key={data.date}>
                                                                <Chip
                                                                    label={data.date}
                                                                />
                                                            </ListItem>
                                                        );
                                                    })}
                                                    <Container>
                                                        <Tooltip title="Agregar tarea" placement="right">
                                                            <IconButton aria-label="edit" onClick={() => { handleClickOpenTarea(index, indexFechaRecordar, undefined) }} size="small" sx={{ marginTop: "20px", display: `${mostrarEditar}` }}>
                                                                <AddIcon fontSize="inherit" sx={{ color: "greenyellow" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Typography variant="caption" display="block">
                                                            Tareas: {data.Tareas.length}
                                                        </Typography>
                                                    </Container>
                                                    {data.Tareas.map((data, indexTarea) => {
                                                        return (
                                                            <Paper variant="outlined"
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'left',
                                                                    flexWrap: 'wrap',
                                                                    listStyle: 'none',
                                                                    p: 0.5,
                                                                    m: 0,
                                                                    padding: "7px",
                                                                    marginLeft: "17px"
                                                                }}
                                                                component="ul"
                                                            >
                                                                <Container>
                                                                    <Typography variant="h5" display="flex">
                                                                        {data.DescripcionTarea}
                                                                        <Container sx={{ display: "contents" }}>
                                                                            <Tooltip title="Modificar tarea" placement="right">
                                                                                <IconButton aria-label="edit" onClick={() => { handleClickOpenTarea(index, indexFechaRecordar, indexTarea) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                                                    <EditIcon fontSize="inherit" sx={{ color: "yellow" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            <Tooltip title="Eliminar" placement="bottom">
                                                                                <IconButton aria-label="edit" onClick={() => { handleClickOpenDeleteTarea(index, indexFechaRecordar, indexTarea) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                                                    <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Container>
                                                                    </Typography>
                                                                    <Typography variant="caption" display="block" color={data.Estado === "en proceso" ? "yellow" : "greenyellow"}>
                                                                        {data.Estado}
                                                                        <ToggleButton
                                                                            value="check"
                                                                            size='small'
                                                                            sx={{ width: "7%", height: "9px", marginLeft: "10px", display: `${mostrarEditar}` }}
                                                                            onChange={() => { handleChangeEstadoTarea(index, indexFechaRecordar, indexTarea) }}
                                                                        >
                                                                            {checkSelected(index, indexFechaRecordar, indexTarea) ? <ClearIcon /> : <CheckIcon />}
                                                                        </ToggleButton>
                                                                    </Typography>
                                                                    <Typography variant="caption" display="block">
                                                                        {data.Nota == true ? "con nota" : "sin nota"}
                                                                    </Typography>
                                                                </Container>
                                                            </Paper>
                                                        );
                                                    })}
                                                </Paper>
                                            );
                                        })}


                                    </Paper>

                                </Paper>
                            );
                        })}
                        <Tooltip title="Agregar reunion" placement="right">
                            <IconButton aria-label="edit" onClick={() => { handleClickOpenReunion(undefined) }} size="small" sx={{ marginTop: "20px", display: `${mostrarEditar}` }}>
                                <AddIcon fontSize="inherit" sx={{ color: "greenyellow" }} />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="caption" display="block" sx={{ marginTop: "16px" }}>
                            Reuniones: {reuniones.length}
                        </Typography>
                        <Paper variant='outlined'
                            sx={{
                                display: "flex",
                                justifyContent: 'left',
                                flexWrap: 'wrap',
                            }}
                        >
                            {reuniones.map((data, indexReunion) => {
                                return (
                                    <Paper variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'left',
                                            flexWrap: 'wrap',
                                            listStyle: 'none',
                                            p: 0.5,
                                            m: 0,
                                            padding: "7px",
                                            margin: "17px",
                                            marginLeft: "17px",
                                            width: "fit-content"
                                        }}
                                        component="ul"
                                    >
                                        <Container>
                                            <Typography variant="h5" display="flex">
                                                {data.tituloReunion}
                                                <Container sx={{ display: "contents" }}>
                                                    <Tooltip title="Modificar reunion" placement="right">
                                                        <IconButton aria-label="edit" onClick={() => { handleClickOpenReunion(indexReunion) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                            <EditIcon fontSize="inherit" sx={{ color: "yellow" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Eliminar" placement="bottom">
                                                        <IconButton aria-label="edit" onClick={() => { handleClickOpenDeleteReunion(indexReunion) }} size="small" sx={{ display: `${mostrarEditar}` }}>
                                                            <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Container>
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                                {data.DescripcionReunion}
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                                {data.Dia.date}
                                            </Typography>
                                            <Typography variant="caption" display="block">
                                                {data.Plataforma}
                                            </Typography>
                                        </Container>
                                    </Paper>
                                );
                            })}
                        </Paper>
                    </CardContent>
                </Card>
            </Grid>
        </ViewNotaContext.Provider>
    );

}