import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import NoteIcon from '@mui/icons-material/Note';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export default function TemporaryDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem key={"Notas"} disablePadding>
                    <Link to='notas' style={{ textDecoration: 'none', color: "white", width: "100%" }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <NoteIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Notas"} />
                        </ListItemButton>
                    </Link>
                </ListItem>

                <ListItem key={"Materias"} disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Materias"} />
                    </ListItemButton>
                </ListItem>


            </List>
            <Divider />
            <List>

                <ListItem key={"Amigos"} disablePadding>
                    <Link to='veramigos' style={{ textDecoration: 'none', color: "white", width: "100%" }}>
                        <ListItemButton>
                            <ListItemIcon>
                                <NoteIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Amigos"} />
                        </ListItemButton>
                    </Link>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            {
                <React.Fragment key={'left'}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer('left', true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                    >
                        {list('left')}
                    </Drawer>
                </React.Fragment>
            }
        </div>
    );
}
