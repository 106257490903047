import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import { ViewNotaContext } from './viewSingle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function EditTitulo() {

    const { notaMod, editTitulo, DeleteNOTA } = useContext(ViewNotaContext);
    const navigate = useNavigate();
    const [openTitulo, setOpenTitulo] = editTitulo
    const [nota, setNota] = notaMod
    const [openDeleteNOTA, setOpenDeleteNOTA] = DeleteNOTA


    const handleSendTitulo = (t, d) => {
        const notaNew = nota
        notaNew.Titulo = t
        notaNew.Descripcion = d
        setNota(notaNew)
        setOpenTitulo(false);
    };

    const handleCloseTitulo = () => {
        setOpenTitulo(false);
    };

    const handleCloseDeleteNOTA = () => {
        setOpenDeleteNOTA(false)
    }

    const handleDeleteNOTA = () => {
        axios.delete(`${process.env.REACT_APP_CURRENT_URL_TO_API}/notas/${nota._id}`)
            .then(response => {
                navigate("/notas");
            })
    }

    return <div>
        <Dialog open={openTitulo} onClose={handleCloseTitulo}>
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modificar título y descripción
                </DialogContentText>
                <TextField
                    autoFocus
                    multiline
                    margin="dense"
                    id="titulo"
                    label="Título"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={nota.Titulo}
                />
                <TextField
                    autoFocus
                    multiline
                    margin="dense"
                    id="desc"
                    label="Descripción"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={nota.Descripcion}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseTitulo}>Cancel</Button>
                <Button onClick={() => {
                    handleSendTitulo(document.getElementById('titulo').value,
                        document.getElementById('desc').value
                    )
                }}>Modificar</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openDeleteNOTA}
            onClose={handleCloseDeleteNOTA}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Eliminar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro de eliminar la nota?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteNOTA}>Cancelar</Button>
                <Button sx={{ color: "red" }} onClick={handleDeleteNOTA} autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    </div>;

}