import '@fontsource/roboto';
import * as React from 'react';
import { NoteContext } from '../CreateNote'
import { useEffect, useState, useContext, useRef, createContext } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export default function StepThree() {

    const DescripcionReunion = useRef();
    const TituloReunion = useRef();

    const { Reunion } = useContext(NoteContext);
    const [ReunionObj, setReunionObj] = Reunion
    const [fechaReunion, setfechaReunion] = React.useState(new Date())

    const handleDelete = (chipToDelete) => () => {
        setReunionObj((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleAddReunion = () => {
        const DatefechaReunion = new Date(fechaReunion)
        let OBJETOReunion = {
            "tituloReunion": TituloReunion.current.value,
            "DescripcionReunion": DescripcionReunion.current.value,
            "Dia": { "date": DatefechaReunion }
        }
        setReunionObj(state => ({ ...state }))
        setReunionObj(ReunionObj.concat(OBJETOReunion))
    }

    return (
        <React.Fragment>
            <Container maxWidth="sm">
                <Grid item xs={1} sm={2}>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                            marginBottom: "15px"
                        }}
                        component="ul"
                    >
                        {ReunionObj.map((data, index) => {
                            return (
                                <ListItem key={index}>
                                    <Chip
                                        label={data.tituloReunion}
                                        onDelete={handleDelete(data)}
                                    />
                                </ListItem>
                            );
                        })}
                    </Paper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="DateTimePicker"
                            onChange={(newValue) => {
                                setfechaReunion(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    <TextField
                        multiline
                        sx={{ width: "100%" }}
                        id="standard-basic"
                        inputRef={TituloReunion}
                        label="Titulo"
                        variant="standard"
                    />
                    <TextField
                        multiline
                        sx={{ width: "100%" }}
                        id="standard-basic"
                        inputRef={DescripcionReunion}
                        label="Descripción"
                        variant="standard"
                    />
                    <Button
                        onClick={handleAddReunion}
                        size="small"
                        sx={{
                            width : "100%",
                            margin : "auto",
                            marginTop : "15px"
                        }}
                    >
                        Agregar Reunión
                    </Button>
                </Grid>
            </Container>
        </React.Fragment>
    );

}