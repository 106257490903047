import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepOne from './PasosCrear/StepOne';
import StepTwo from './PasosCrear/StepTwo'
import StepThree from './PasosCrear/StepThree';
import CssBaseline from '@mui/material/CssBaseline';
import { useState, createContext, useEffect } from "react";
import { parseJwt } from '../../utils/cookiesUtils';
import axios from 'axios';
import { Link } from 'react-router-dom';


const steps = ['Datos básicos', 'Añadir recordatorios', 'Crear reuniones'];


export const NoteContext = createContext([{}, () => { }]);

export default function CrearNota() {

    const [alumnoId, setAlumnoId] = useState()

    const handleID = (number) => {
        setAlumnoId(number)
    }

    useEffect(() => {
        const setNotAndMails = () => {
            const data = parseJwt()
            if (data != undefined) {
                const alumno = data.usuario
                handleID(alumno.name)
            }
        }
        setNotAndMails()
    }, [])

    const [personName, setPersonName] = React.useState([]);
    const [fechaHora, setfechaHora] = React.useState(new Date());
    const [titulo, setTitulo] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [RecObj, setRecData] = useState([]);
    const [ReunionObj, setReunionObj] = useState([]);

    const values = {
        "persons": [personName, setPersonName],
        "fechaNota": [fechaHora, setfechaHora],
        "tituloNota": [titulo, setTitulo],
        "desc": [descripcion, setDescripcion],
        "RecordatorioObj": [RecObj, setRecData],
        "Reunion": [ReunionObj, setReunionObj],
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1 || step === 2;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        const finalDate = new Date(fechaHora);
        const body = {
            "Titulo": titulo,
            "Descripcion": descripcion,
            "AlumnoCreador": alumnoId,
            "FechaFinal": finalDate,
            "Estado": "en proceso",
            "Alumnos": personName,
            "Recordatorios": RecObj,
            "Reuniones": ReunionObj
        }
        axios.post(`${process.env.REACT_APP_CURRENT_URL_TO_API}/notas`, body)
            .then((message) => {
                console.log(message)
            })
            .catch((error) => {
                console.log(error)
            })
        setActiveStep(0);
    };

    function getContent(step) {
        switch (step) {
            case 0:
                return <StepOne />
            case 1:
                return <StepTwo />
            case 2:
                return <StepThree />
            default:
                throw new Error('Unknown step');
        }
    }

    return (
        <NoteContext.Provider value={values}>
            <Box
                sx={{
                    width: '75%',
                    backgroundColor: "#272727",
                    margin: "auto",
                    borderRadius: "5px",
                    marginTop: "67px",
                    padding: "30px"
                }}
            >
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = (
                                <Typography
                                    variant="caption">
                                    Optional
                                </Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <CssBaseline />
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Todos los pasos se completaron, hagá click en terminar para subir los cambios.
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                pt: 2
                            }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                onClick={handleBack}>
                                Volver
                            </Button>
                            <Button color="success"
                                onClick={handleReset}
                                LinkComponent={Link} to={'/notas'}
                            >
                                Terminar
                            </Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <CssBaseline />
                        <Typography
                            sx={{
                                mt: 2,
                                mb: 1
                            }}>
                            Paso {activeStep + 1}
                        </Typography>
                        {getContent(activeStep)}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                pt: 2
                            }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Atrás
                            </Button>
                            <Box
                                sx={{ flex: '1 1 auto' }}
                            />
                            {isStepOptional(activeStep) && (
                                <Button
                                    color="inherit"
                                    onClick={handleSkip}
                                    sx={{ mr: 1 }}
                                >
                                    Saltear
                                </Button>
                            )}

                            <Button onClick={handleNext}>
                                Siguiente
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </NoteContext.Provider>

    );
}
