import '@fontsource/roboto';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useEffect, useState, useContext } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { RecordatorioContext } from './StepTwo';
import randomNumberInRange from '../../../utils/random'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function MateriasSelect() {

    const theme = useTheme();

    const {materiasData, fechaRec}  =  useContext(RecordatorioContext)
    const [materiasName, setMateriasName] = materiasData
    const [materias, setMaterias] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/materias`)
            .then((receive) => {
                const materiasData = receive.data
                setMaterias(materiasData.message)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        setMateriasName(state => ({ ...state }))
        setMateriasName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

    };

    const handleDelete = (h) => {
        setMateriasName((materiasName) =>
            materiasName.filter((hashtag) => hashtag !== h)
        )
    }

    return (
            <Grid item xs={1} sm={2} sx={{
                marginBottom : "20px"
            }}>
                <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-chip-label">Materias</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={materiasName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" label="Materias" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value._id} label={value.Nombre} onMouseDown={e => {
                                        e.stopPropagation()
                                    }}
                                        onDelete={() => {
                                            handleDelete(value)
                                        }}
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {materias.map((name) => (
                            <MenuItem
                                key={name._id}
                                value={name}
                                nombre={name.Nombre}
                                style={getStyles(name.Nombre, materiasName, theme)}
                            >
                                {name.Nombre}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
    );
}