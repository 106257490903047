import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Outlet, Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { parseJwt, checkLogging } from '../utils/cookiesUtils'
import { socket } from '../utils/webSocketUtils'
import TemporaryDrawer from './drawer'
import axios from 'axios';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useCookies } from "react-cookie";
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ClearIcon from '@mui/icons-material/Clear';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function NotificationAdd() {
    const { enqueueSnackbar } = useSnackbar();

    const handleClickVariant = (mensaje, variant) => {
        enqueueSnackbar(mensaje, { variant });
    };

    socket.on("receive_message", (data) => {
        console.log(data)
        if (data.message.remitente == undefined) handleClickVariant(`${data.message.contenido}`, 'info')
        else handleClickVariant(`¡Solicitud de ${data.message.tipo} de ${data.message.remitente}!`, 'info')
    });

}

const Notification = React.memo(NotificationAdd);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function PrimarySearchAppBar() {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const [boton, setBoton] = React.useState(null);
    const [logged, setLogged] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [nombre, setNombre] = React.useState("");
    const [apellido, setApellido] = React.useState("");

    const [cookies, setCookie, removeCookie] = useCookies(["token"]);
    const [cookies2, setCookie2, removeCookie2] = useCookies(["isLogged"]);

    const [newNotifications, setNewNotifications] = React.useState(0);
    const [newMails, setNewMails] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteUser = () => {
        const data = parseJwt()
        if (data != undefined) {
            const options = {
                withCredentials: true
            }
            axios.delete(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos/${data.usuario.name}`, options)
            .then(() => {
                setOpen(false);
                navigate('../login')
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    const navigate = useNavigate()

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const Logout = () => {
        removeCookie("token");
        removeCookie2("isLogged");
        setAnchorEl(null);
        handleMobileMenuClose();
        navigate('../login')
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleNot = (number) => {
        console.log(number)
        setNewNotifications(number)
    }

    const handleMail = (number) => {
        console.log(number)
        setNewMails(number)
    }

    socket.on("receive_message", () => {
        const data = parseJwt()
        if (data != undefined) {
            const options = {
                withCredentials: true
            }
            axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos?User=${data.usuario.name}`, options)
                .then((response) => {
                    const alumno = response.data.message[0]
                    if (alumno != undefined) {
                        handleNot(alumno.Notificaciones.length)
                        handleMail(alumno.Solicitudes.length)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    });

    useEffect(() => {
        const setNotAndMails = () => {
            const data = parseJwt()
            console.log(data)
            if (data != undefined) {
                const options = {
                    withCredentials: true
                }
                axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/alumnos?User=${data.usuario.name}`, options)
                    .then((response) => {
                        const alumno = response.data.message[0]
                        if (alumno != undefined) {
                            handleNot(alumno.Notificaciones.length)
                            handleMail(alumno.Solicitudes.length)
                            setNombre(alumno.Nombre)
                            setApellido(alumno.Apellido)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        }
        setNotAndMails()
    }, [])

    useEffect(() => {
        const dataToken = parseJwt()
        if (dataToken != undefined) {
            const joinRoom = () => {
                if (dataToken.usuario.name !== "") {
                    socket.emit("join_room", dataToken.usuario.name);
                }
            };
            joinRoom()
        }
    }, [])


    useEffect(() => {
        if (checkLogging()) setLogged(true)
        else setLogged(false)
    }, [])

    const removeSol = () => {
        handleMail(0)
    }

    const removeNot = () => {
        handleNot(0)
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <p>{nombre} {apellido}</p>
            <IconButton id="boton-eliminar-cuenta" aria-label="edit" onClick={handleClickOpen} size="small" >
                <ClearIcon fontSize="inherit" sx={{ color: "red" }} />
            </IconButton>
            <MenuItem onClick={Logout}>Log out</MenuItem>
        </Menu>
    );


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" color="inherit"
                    onClick={removeSol}
                    LinkComponent={Link} to={'/solicitudes'}
                >
                    <Badge badgeContent={newMails} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Solicitudes</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={removeNot}
                    LinkComponent={Link} to={'/notificaciones'}
                >
                    <Badge badgeContent={newNotifications} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notificaciones</p>
            </MenuItem>
            <MenuItem id="boton-usuario-opciones" onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    useEffect(() => {
        const checkLoggingSection = function checkLog() {
            if (checkLogging()) {
                console.log(newMails)
                setBoton(
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                            size="large"
                            aria-label="show new mails"
                            color="inherit"
                            onClick={removeSol}
                            LinkComponent={Link} to={'/solicitudes'}
                        >
                            <Badge badgeContent={newMails} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show new notifications"
                            color="inherit"
                            onClick={removeNot}
                            LinkComponent={Link} to={'/notificaciones'}
                        >
                            <Badge badgeContent={newNotifications} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                            id="boton-usuario-opciones"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                )

            }
            else {
                setBoton(
                    <Button
                        sx={{ marginLeft: "7px" }}
                        size="large"
                        edge="end"
                        aria-label="log in"
                        color="inherit"
                        id="LOGIN_BUTTON"
                        LinkComponent={Link} to={'/login'}
                    >
                        Log in
                    </Button>
                )
            }
        }
        checkLoggingSection()
    }, [newMails, newNotifications])

    return (
        <SnackbarProvider maxSnack={3}>
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Use Google's location service?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            ¿Está seguro de que quiere eliminar su cuenta?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button id="eliminar-cuenta" sx={{color : "red"}} onClick={handleDeleteUser}>Eliminar cuenta</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Notification />
            <ThemeProvider theme={darkTheme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <TemporaryDrawer />
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                                Poliwork
                            </Typography>

                            <Box sx={{ flexGrow: 1 }} />
                            {boton}
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    {checkLogging() ? renderMobileMenu : undefined}
                    {renderMenu}
                </Box>
                <Outlet />
            </ThemeProvider>
        </SnackbarProvider>
    );
}
