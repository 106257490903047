import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import { ViewNotaContext } from './viewSingle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FechasRecordar from './fechasRecordar'

export const agregarFechaContext = createContext([{}, () => { }]);

export default function AgregarFecha() {

    const [fechasRec, setFechasRec] = useState([])
    const { notaMod, egregarFec, IndexRecEdit, DeleteFecha, indexFechaRecordar } = useContext(ViewNotaContext);
    const [openFecEdit, setOpenFecEdit] = egregarFec
    const [openDeleteFecha, setOpenDeleteFecha] = DeleteFecha
    const [indexFecha] = indexFechaRecordar
    const [nota, setNota] = notaMod
    const [indexRec] = IndexRecEdit
    const notaNew = nota
    const values = {

        "fechaRec": [fechasRec, setFechasRec]

    }

    const handleClickOpenTitulo = () => {
        openFecEdit(true);
    };

    const handleSendTitulo = (t, d) => {
        console.log(fechasRec)
        notaNew.Recordatorios[indexRec].FechasRecordar.push({
            "Fechas": fechasRec,
            "Tareas": []
        })
        setNota(notaNew)
        setOpenFecEdit(false);
    };

    const handleCloseTitulo = () => {
        setOpenFecEdit(false);
    };

    const handleDeleteFecha = () => {
        notaNew.Recordatorios[indexRec].FechasRecordar.splice(indexFecha, 1)
        setNota(notaNew)
        setOpenDeleteFecha(false)
    }

    const handleCloseDeleteFecha = () => {
        setOpenDeleteFecha(false)
    }


    return <agregarFechaContext.Provider value={values}>
        <Dialog open={openFecEdit} onClose={handleCloseTitulo}>
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Modificar título y descripción
                </DialogContentText>
                <FechasRecordar />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseTitulo}>Cancel</Button>
                <Button onClick={() => {
                    handleSendTitulo()
                }}>Modificar</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openDeleteFecha}
            onClose={handleCloseDeleteFecha}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Eliminar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Esta seguro de eliminar las fechas y tareas?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDeleteFecha}>Cancelar</Button>
                <Button sx={{ color: "red" }} onClick={handleDeleteFecha} autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    </agregarFechaContext.Provider>;

}