import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { RecordatorioContext } from './StepTwo'
import { useContext, useRef } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


export default function TareasRecordar() {
    const checkRef = useRef();
    const { tareas } = useContext(RecordatorioContext)
    const [tareasObj, setTareasObj] = tareas

    const handleDelete = (chipToDelete) => () => {
        setTareasObj((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleAddTarea = (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let checkNota = checkRef.current.checked;
        const OBJETOtarea = {
            'Estado': 'en proceso',
            'DescripcionTarea': data.get('desc'),
            'Nota': checkNota
        }
        setTareasObj(state => ({ ...state }))
        setTareasObj(tareasObj.concat(OBJETOtarea))
        console.log(tareasObj)
    }

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleAddTarea}>
                <Grid item xs={1} sm={2} sx={{
                    marginTop: "20px"
                }}>
                    <Paper
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0.5,
                            m: 0,
                            marginBottom: "15px"
                        }}
                        component="ul"
                    >
                        {tareasObj.map((data, index) => {
                            return (
                                <ListItem key={index}>
                                    <Chip
                                        label={data.DescripcionTarea}
                                        onDelete={handleDelete(data)}
                                    />
                                </ListItem>
                            );
                        })}
                    </Paper>
                    <TextField
                        id="desc"
                        name="desc"
                        multiline sx={{ width: "100%" }}
                        label="Descripción"
                        variant="standard"
                    />
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        direction="row"
                    >
                        <FormGroup>
                            <FormControlLabel
                                name="check"
                                control={
                                    <Checkbox
                                        inputRef={checkRef}
                                    />
                                }
                                label="Con nota"
                            />
                        </FormGroup>
                        <Button
                            type="submit"
                            size="small">
                            Agregar Tarea
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
}
