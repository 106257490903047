import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { RecordatorioContext } from './StepTwo'
import { useEffect, useState, useContext, useRef, createContext } from "react";
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TareasRecordar from './TareasRecordar'
import randomNumberInRange from '../../../utils/random'


const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));


export default function FechasRecordar() {

    const { fechaRec, tareas } = useContext(RecordatorioContext)
    const [fechasRec, setFechasRec] = fechaRec
    const [fechas, setFechas] = useState([])
    const [tareasObj, setTareasObj] = tareas
    const [value, setValue] = React.useState(dayjs());

    const handleDelete = (chipToDelete) => () => {
        setFechas((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleDeleteObj = (chipToDelete) => () => {
        setFechasRec((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleAddFechaRec = () => {
        const OBJETOFechas = {
            "Fechas": fechas,
            "Tareas": tareasObj
        }
        setFechasRec(state => ({ ...state }))
        setFechasRec(fechasRec.concat(OBJETOFechas))
    }

    const handleAddFecha = () => {
        setFechas(state => ({ ...state }))
        setFechas(fechas.concat({ "date": new Date(value) }))
        console.log(fechas)
    }

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return (
        <React.Fragment>

            <Grid item xs={1} sm={2} sx={{
                marginBottom: "20px"
            }}>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                        marginBottom: "15px"
                    }}
                    component="ul"
                >
                    {fechasRec.map((data, index) => {
                        return (
                            <ListItem key={index}>
                                <Chip
                                    label={`Nueva fecha (${data.Fechas.length})`}
                                    onDelete={handleDeleteObj(data)}
                                />
                            </ListItem>
                        );
                    })}
                </Paper>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        p: 0.5,
                        m: 0,
                        marginBottom: "15px"
                    }}
                    component="ul"
                >
                    {fechas.map((data) => {
                        return (
                            <ListItem key={data.$s}>
                                <Chip
                                    label={data.date.toString()}
                                    onDelete={handleDelete(data)}
                                />
                            </ListItem>
                        );
                    })}
                </Paper>
                <Grid container
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="DateTimePicker"
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                        />
                    </LocalizationProvider>
                    <Button
                        onClick={handleAddFecha}
                        size="small">
                        Agregar fecha
                    </Button>
                </Grid>
                <TareasRecordar />
                <Button
                    onClick={handleAddFechaRec}
                    size="small">
                    Agregar fecha recordar
                </Button>
            </Grid>

        </React.Fragment>
    );
}
