import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import { ViewNotaContext } from './viewSingle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MateriasSelect from './materiasSelect'

export const AgregarRecContext = createContext([{}, () => { }], { error: false });

export default function AgregarRec() {

    const { notaMod, agregarRec, IndexRecEdit, DeleteRec } = useContext(ViewNotaContext);

    const [openRec, setOpenRec] = agregarRec
    const [openDeleteRec, setOpenDeleteRec] = DeleteRec
    const [nota, setNota] = notaMod
    const [indexRec] = IndexRecEdit
    const [materias, setMaterias] = useState([])
    const notaNew = nota

    const values = {
        "mat": [materias, setMaterias]
    }

    const handleClickOpenRec = () => {
        setOpenRec(true);
    };

    const handleSendRec = (t) => {

        if (indexRec != undefined) {
            notaNew.Recordatorios[indexRec].DescripcionRecordatorio = t
            notaNew.Recordatorios[indexRec].Materia = materias
            setNota(notaNew)
            setOpenRec(false);
            return 0;
        }

        else {
            const newRec = {
                "DescripcionRecordatorio": t,
                "Materia": materias,
                "FechasRecordar": []
            }

            notaNew.Recordatorios.push(newRec)
        }
        setNota(notaNew)
        setOpenRec(false);
    };

    const handleDeleteRec = () => {
        notaNew.Recordatorios.splice(indexRec, 1)
        setNota(notaNew)
        setOpenDeleteRec(false)
    }

    const handleCloseRec = () => {
        setOpenRec(false);
    };

    const handleCloseRecDelete = () => {
        setOpenDeleteRec(false)
    }

    return <AgregarRecContext.Provider value={values}>
        <Dialog open={openRec} onClose={handleCloseRec}>
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Agregar reunion
                </DialogContentText>
                <TextField
                    autoFocus
                    multiline
                    margin="dense"
                    id="desc"
                    label="Descripción"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={indexRec != undefined &&  nota.Recordatorios != undefined && nota.Recordatorios[indexRec] != undefined? 
                        nota.Recordatorios[indexRec].DescripcionRecordatorio : ""}
                />

                <MateriasSelect />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseRec}>Cancel</Button>
                <Button onClick={() => {
                    handleSendRec(document.getElementById('desc').value)
                }}>Modificar</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openDeleteRec}
            onClose={handleCloseRecDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Eliminar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Esta seguro de eliminar el recordatorio?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseRecDelete}>Cancelar</Button>
                <Button sx={{color: "red"}} onClick={handleDeleteRec} autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    </AgregarRecContext.Provider >;

}