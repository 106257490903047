import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState } from "react";
import SingleNote from './single';
import { Grid } from '@mui/material';
import axios from 'axios';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { parseJwt } from '../../utils/cookiesUtils'

let alumnoName = ""

export default function ViewAllNotes() {

    const [notas, setNotas] = useState([])
    const [notasPropias, setNotasPropias] = useState([])
    const [alignment, setAlignment] = React.useState('');
    const [disabledP, setDisableP] = useState(true)
    const [disabledT, setDisableT] = useState(true)

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    useEffect(() => {
        let propArray = []
        let todosArray = []
        axios.get(`${process.env.REACT_APP_CURRENT_URL_TO_API}/notas`)
            .then((receive) => {
                const notasData = receive.data.message.flat(1)
                const data = parseJwt()
                if (data != undefined) alumnoName = data.usuario.name
                notasData.map((nota) => {
                    if (nota != undefined) {
                        if (nota.AlumnoCreador == alumnoName) {
                            propArray.push(nota)
                            todosArray.push(nota)
                            setDisableP(false)
                        }
                        else {
                            nota.Alumnos.map((alumno) => {
                                if (alumno.Alumno != undefined) {
                                    if (alumno.Alumno == alumnoName && alumno.estado == "aceptada")
                                        todosArray.push(nota)
                                }
                            })
                        }
                    }
                })
                setNotasPropias(propArray)
                setNotas(todosArray)
                if (notasData.message != []) setDisableT(false)
                setAlignment('todas')
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    function obtenerNotasFunction() {
        if (alignment === "propias") {
            console.log(notasPropias)
            return (notasPropias.map((nota) => {
                return <Grid item xs sm md key={nota._id}>

                    <SingleNote
                        titulo={nota.Titulo}
                        desc={nota.Descripcion}
                        estado={nota.Estado}
                        idNota={nota._id}
                    />

                </Grid>
            }))
        }
        else {
            return (notas.map((nota) => {
                return <Grid item xs sm md key={nota._id}>

                    <SingleNote
                        titulo={nota.Titulo}
                        desc={nota.Descripcion}
                        estado={nota.Estado}
                        idNota={nota._id}
                    />

                </Grid>
            }))
        }
    }



    return (
        <React.Fragment>
            <CssBaseline />
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                style={{ padding: "0 60px 0 60px" }}
            >
                <h1>Notas</h1>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="Platform"
                >
                    <ToggleButton disabled={disabledT} value="todas">Todas</ToggleButton>
                    <ToggleButton disabled={disabledP} value="propias">Propias</ToggleButton>
                </ToggleButtonGroup>
                <Button
                    LinkComponent={Link} to={'/crearNotas'}
                    variant="contained"
                >
                    Agregar nota
                </Button>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={10}
                columns={{ xs: 1, sm: 6, md: 12 }}
                style={{ padding: "0 60px 60px 60px" }}
            >

                {obtenerNotasFunction()}

            </Grid>
        </React.Fragment>
    );

}