import logo from './logo.svg';
import './App.css';
import Router from './components/Router';


function App() {
  return (
    <Router/>
  );
}

export default App;
