import '@fontsource/roboto';
import * as React from 'react';
import { useEffect, useState, useContext, useRef, createContext } from "react";
import { ViewNotaContext } from './viewSingle';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MateriasSelect from './materiasSelect'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';


export default function AgregarReunion() {

    const { notaMod, agregarReunion, indexReu, DeleteReunion } = useContext(ViewNotaContext);
    const checkRef = useRef();
    const [openReunion, setOpenReunion] = agregarReunion
    const [nota, setNota] = notaMod
    const [indexReunion] = indexReu
    const [openReunionDelete, setOpenReunionDelete] = DeleteReunion
    const notaNew = nota
    const [fechaReunion, setfechaReunion] = useState(new Date())



    const handleClickOpenRec = () => {
        setOpenReunion(true);
    };


    const handleSendRec = (d, t) => {
        const f = new Date(fechaReunion)
        const reunion = {
            "tituloReunion": t,
            "DescripcionReunion": d,
            "Dia": { "date": `${f.toDateString()} ${f.getHours()}:${f.getMinutes()}:${f.getSeconds()}` },
            "Plataforma": ""
        }
        if (indexReunion != undefined) {
            notaNew.Reuniones[indexReunion] = reunion
            setNota(notaNew)
            setOpenReunion(false);
            return 0;
        }

        else {
            notaNew.Reuniones.push(reunion)
        }
        setNota(notaNew)
        setOpenReunion(false);
    };

    const handleCloseRec = () => {
        setOpenReunion(false);
    };

    const handleCloseReunionDelete = () => {
        setOpenReunionDelete(false)
    }

    const handleDeleteReunion = () => {
        notaNew.Reuniones.splice(indexReunion, 1)
        setNota(notaNew)
        setOpenReunionDelete(false)
    }

    return <React.Fragment>
        <Dialog open={openReunion} onClose={handleCloseRec}>
            <DialogTitle>Editar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Agregar reunion
                </DialogContentText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="DateTimePicker"
                        onChange={(newValue) => {
                            setfechaReunion(newValue);
                        }}
                    />
                </LocalizationProvider>
                <TextField
                    multiline
                    sx={{ width: "100%" }}
                    id="titulo"
                    label="Titulo"
                    variant="standard"
                />
                <TextField
                    multiline
                    sx={{ width: "100%" }}
                    id="desc"
                    label="Descripción"
                    variant="standard"
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseRec}>Cancel</Button>
                <Button onClick={() => {
                    handleSendRec(document.getElementById('desc').value, document.getElementById('titulo').value)
                }}>Modificar</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openReunionDelete}
            onClose={handleCloseReunionDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Eliminar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Está seguro de eliminar la reunión?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseReunionDelete}>Cancelar</Button>
                <Button sx={{ color: "red" }} onClick={handleDeleteReunion} autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment >;

}